import React from 'react';
import PropTypes from 'prop-types';

const False = ({
  className = '',
  color = '#B7BCBF',
  height = '27px',
  width = '27px',
  unBoxed = false,
}) => (
    <svg
      version="1.1"
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26.9 26.9">
      {!unBoxed && (
        <path
          fill="#f4f4f4"
          d="M23.5,26.9H3.4c-1.9,0-3.4-1.5-3.4-3.4V3.4C0,1.5,1.5,0,3.4,0h20.1c1.9,0,3.4,1.5,3.4,3.4v20.1C26.9,25.4,25.4,26.9,23.5,26.9z"
        />
      )}
      <polygon
        fill={color}
        points="19.7,8.6 18.4,7.3 13.4,12.3 8.5,7.3 7.2,8.6 12.2,13.6 7.2,18.5 8.5,19.8 13.4,14.8 18.4,19.8 19.7,18.5 14.7,13.6 "
      />
    </svg>
  );
False.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  unBoxed: PropTypes.bool,
};
export default False;
