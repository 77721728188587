/* eslint-disable react/jsx-fragments */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {graphql} from '@apollo/client/react/hoc';
import cx from 'classnames';
import humanDuration from 'core/libs/helpers/humanDuration';
import CourseNextStepButton from 'modules/common/CourseNextStepButton';
import JOIN_TRACK from 'modules/common/graphql/joinTrack.graphql';
import Link from 'modules/common/Link';
import ProgressBar from 'modules/common/ProgressBar';
import VideoModal from 'modules/common/VideoModal';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import {compose} from 'recompose';
import NewTrackBoxFooterCompleted from './NewTrackBoxFooterCompleted';
import {trackBoxHeight, vertical} from './styles.scss';

const {PARTNER} = process.env;

export class NewTrackBox extends Component {
  state = {
    trailerOn: false,
  };

  toggleTrailer = () => {
    this.setState((state) => ({trailerOn: !state.trailerOn}));
  };

  render() {
    const {
      trackProgress,
      stepsProgress,
      isDashboard,
      joinTrack: joinTrackMutation,
      authenticated,
      track,
      isDegreeBox,
      subscription,
    } = this.props;
    const joinTrack =
      authenticated && !isDegreeBox ? joinTrackMutation : undefined;
    const trackLink =
       `${isDegreeBox || track.isDegree ? '/degrees' : ''}/${track.slug}`

    //const degreeLink = isDegreeBox ? track && track.landingPageUrl : undefined;
    let percentage = 0;
    let videoTime = 0;
    let remainingVideoTime = 0;
    if (track.courses) {
      let totalStepsInTrack = 0;
      const totalStepsCompleted = track.courses.reduce((acc, course) => {
        let stepsCompletedForCourse = 0;
        if (course.stages)
          course.stages.forEach((stage) => {
            stage.steps.forEach((step) => {
              totalStepsInTrack += 1;
              if (step.videotutorial && step.duration) {
                videoTime += step.duration;
              }
              if (
                stepsProgress[step.id] &&
                stepsProgress[step.id] === 'completed'
              ) {
                stepsCompletedForCourse += 1;
              } else if (step.videotutorial && step.duration) {
                remainingVideoTime += step.duration;
              }
            });
          });
        return acc + stepsCompletedForCourse;
      }, 0);
      if (totalStepsInTrack) {
        percentage = (100 * totalStepsCompleted) / totalStepsInTrack;
      }
    }
    const bootcampLevel = track && track.level;

    return (
      <div
        className={`flex fr w-third-ns w-50-m w-50-mid w-100 pv4 ph3 ${trackBoxHeight}`}
      >
        <div className="w-100 ba b--light-gray bw1 br2 tc flex flex-column justify-between">
          <Fragment>
            <Link
              className="flex1 dark-gray pointer link dim"
              onClick={joinTrack}
              to={trackLink}
            >
              <div className={cx({'h4-ns': isDashboard})}>
                <div className="pt3">
                  {track.icon && (
                    <img
                      height="44"
                      width="44"
                      src={track.icon.url}
                      alt="track icon"
                    />
                  )}
                </div>
                <h2 className="f5 ph3 pv3">{track.name}</h2>
                <p
                  className="silver f6 pv3 ph4"
                  dangerouslySetInnerHTML={{
                    __html:
                      track.description > track.description.slice(0, 120)
                        ? `${track.description.slice(0, 100)}...`
                        : track.description,
                  }}
                />
                {!track.isDegree &&
                trackProgress === 'started' &&
                remainingVideoTime &&
                !isDegreeBox ? (
                  <div className="silver f6">
                    <FormattedMessage id="remaining_time" />{' '}
                    <b>{humanDuration(remainingVideoTime)}</b>
                  </div>
                ) : null}
              </div>
            </Link>
            {track.trailerYoutubeId && !isDashboard && !isDegreeBox && (
              <button
                aria-label="View trailer"
                onClick={this.toggleTrailer}
                className="f7 underline fw6 pv2 pointer color-blue-light bn bg-transparent"
                type="button"
              >
                <FormattedMessage id="view_trailer" />
              </button>
            )}
            {track.landingPageUrl && isDegreeBox && (
              <Link
                className="f7 underline fw6 pv2 pointer color-blue-light bn bg-transparent"
                href={trackLink}
              >
                <FormattedMessage id="explore_degree" />
              </Link>
            )}
            <div
              className={cx('pv4 ph3 bg-near-white', {
                mt3: !isDashboard,
                mt6: isDashboard,
              })}
            >
              {!authenticated && !isDashboard && !isDegreeBox && (
                <Link
                  className="fw6 white pa3 dib no-underline tc br2 f6 bg-blue bg-animate hover-bg-dark-blue"
                  to={`/${track.slug}`}
                >
                  <FormattedMessage id="explore_track" />
                </Link>
              )}
              {trackProgress !== 'started' &&
                trackProgress !== 'completed' &&
                authenticated &&
                !isDegreeBox && (
                  <div className="flex items-center">
                    <div className="pv1 ph3 f7 fw6 dark-gray">
                      <p>
                        {track.courses.length}{' '}
                        <FormattedMessage
                          id={track.courses.length > 10 ? 'course' : 'courses'}
                        />
                      </p>
                    </div>
                    <p className="flex1 br bl b--gray pv1 ph3 f7 fw6 dark-gray">
                      {humanDuration(videoTime)}
                    </p>
                    <p className="pv1 ph3 f7 fw6 dark-gray">{track.level}</p>
                  </div>
                )}
              {isDegreeBox && (
                <div className="flex items-center justify-between">
                  <div className="w-50 center pv1 f7 fw6 dark-gray">
                    <p className="silver">
                      <FormattedMessage id="bootcamp_level" />:
                    </p>
                    <p>{bootcampLevel}</p>
                  </div>
                  <div className={vertical} />
                  <div className="w-50 center pv1 ph3 f7 fw6 dark-gray">
                    <p className="silver">
                      <FormattedMessage id="bootcamp_duration" />:
                    </p>
                    <p>
                      {track.durationInMonths} <FormattedMessage id="months" />
                    </p>
                  </div>
                </div>
              )}
              {trackProgress === 'started' && !isDegreeBox && (
                <div className="flex items-center">
                  <ProgressBar
                    className="flex1 w-60-ns tl"
                    percentage={percentage}
                  />
                  <div className="mh2-ns mh1" />
                  <CourseNextStepButton
                    className="pv2 ph3 f6 bg-blue bg-animate hover-bg-dark-blue lh-copy"
                    trackSlug={track.slug}
                    courses={track.courses}
                    onClick={joinTrack}
                    isDegree={track.isDegree}
                    subscription={subscription}
                  >
                    <FormattedMessage
                      id={track.isDegree ? 'continue_degree' : 'continue_track'}
                    />
                  </CourseNextStepButton>
                </div>
              )}
              {trackProgress === 'completed' && !isDegreeBox && (
                <NewTrackBoxFooterCompleted
                  courses={track.courses}
                  id={track.id}
                  isDegree={track.isDegree}
                  slug={track.slug}
                  name={track.name}
                  onClick={joinTrack}
                />
              )}
            </div>
            {this.state.trailerOn && (
              <VideoModal
                rightButtonClick={this.toggleTrailer}
                videoId={track.trailerYoutubeId}
                title={track.name}
              />
            )}
          </Fragment>
        </div>
      </div>
    );
  }
}

NewTrackBox.propTypes = {
  joinTrack: PropTypes.func,
  trackProgress: PropTypes.string,
  stepsProgress: PropTypes.object,
  isDashboard: PropTypes.bool,
  authenticated: PropTypes.bool,
  isDegreeBox: PropTypes.bool,
  track: PropTypes.shape({
    status: PropTypes.string,
    isDegree: PropTypes.bool,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        duration: PropTypes.number,
        status: PropTypes.string,
      }),
    ),
    description: PropTypes.string.isRequired,
    level: PropTypes.string,
    duration: PropTypes.number,
    icon: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
    trailerYoutubeId: PropTypes.string,
    durationInMonths: PropTypes.number,
    deadlineOffer: PropTypes.string,
    landingPageUrl: PropTypes.string,
    position: PropTypes.number,
  }).isRequired,
};

const enhance = compose(
  graphql(JOIN_TRACK, {
    name: 'joinTrack',
    options: ({track}) => ({
      variables: {id: track.id, partner: PARTNER},
    }),
    skip: ({authenticated}) => !authenticated,
  }),
);

export default enhance(NewTrackBox);
