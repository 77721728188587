/* eslint-disable jsx-a11y/click-events-have-key-events */
import YoutubePlayer from 'modules/common/YoutubePlayer';
import PropTypes from 'prop-types';
import React from 'react';
import HeaderModal from './HeaderModal';

const VideoModal = ({title, videoId, rightButtonClick}) => {
  return (
    <div className="z-9999 fixed h-100 w-100 top-0 left-0 bg-black-90">
      <div className="h-100 w-100" onClick={rightButtonClick} />
      <div className="absolute w-50-l w-90 center-absolute">
        <HeaderModal buttonCloseModal={rightButtonClick} title={title} />
        <YoutubePlayer videoId={videoId} isTrailer />
      </div>
    </div>
  );
};

VideoModal.propTypes = {
  rightButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};

export default VideoModal;
