/* global YT */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import log from 'core/libs/logger';
import customEventConstructor from 'core/libs/helpers/customEventConstructor';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import {responsiveMaxWidth} from './styles.scss';

const origin =
  typeof window === 'object' &&
  `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
const errorMessages = {
  2: 'The request contains an invalid parameter value.',
  5: 'The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.',
  100: 'The video requested was not found. It has been removed or has been marked as private.',
  101: 'The owner of the requested video does not allow it to be played in embedded players.',
  150: 'The owner of the requested video does not allow it to be played in embedded players.',
};
const stateMessages = {
  '-1': 'unstarted',
  0: 'ended',
  1: 'playing',
  2: 'paused',
  3: 'buffering',
  5: 'video cued',
};

class YoutubePlayer extends Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    onVideoPlaying: PropTypes.func,
    onVideoEnded: PropTypes.func,
  };

  state = {
    loading: true,
  };

  handlePlayerReady = () => {
    this.setState({loading: false});
    if (typeof window !== 'undefined' && window.localStorage) {
      const videoSpeed = parseFloat(window.localStorage.getItem('videoSpeed'));
      if (videoSpeed) {
        this.player.setPlaybackRate(videoSpeed);
      }

      const videoQuality = window.localStorage.getItem('videoQuality');
      if (videoQuality) {
        this.player.setPlaybackQuality(videoQuality);
      }
    }
  };

  handlePlayerStateChange = (event) => {
    const {onVideoPlaying, onVideoEnded} = this.props;
    switch (event.data) {
      case 0: // ended
        onVideoEnded();
        break;
      case 1: // playing
        onVideoPlaying();
        break;
      case 2: // paused
        break;
      default:
    }
  };

  handleError = (event) => {
    const {videoId} = this.props;
    const error = `YoutubeError: ${event.data}: ${errorMessages[event.data]}`;
    const state = this.player.getPlayerState();
    log(error, {
      error,
      videoId,
      state: `${state}: ${stateMessages[state]}`,
      currentTime: this.player.getCurrentTime(),
      playbackQuality: this.player.getPlaybackQuality(),
      currentUrl: typeof window !== 'undefined' && window.location.href,
    });
  };

  loadYoutubeAPI = (callback) => {
    this.videoPlayingEvent = customEventConstructor('videoplaying');

    if (window) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        window.isYoutubeAPILoaded = true;
        callback();
      };
    }
  };

  loadYoutubePlayer = () => {
    const {onVideoEnded} = this.props;
    try {
      this.player = new YT.Player('youtubePlayer');
      if (typeof onVideoEnded === 'function') {
        this.player.addEventListener(
          'onStateChange',
          this.handlePlayerStateChange,
        );
      }
      this.player.addEventListener('onError', this.handleError);
      this.player.addEventListener('onReady', this.handlePlayerReady);
      this.player.addEventListener(
        'onPlaybackRateChange',
        this.updateVideoRate,
      );
      this.player.addEventListener(
        'onPlaybackQualityChange',
        this.updateVideoQuality,
      );
    } catch (error) {
      // We want the user to be able to watch the video
      this.setState({loading: false});
      log('Catch error in Youtube player #2510 and #2511', {
        error,
        isYoutubeAPILoaded: window.isYoutubeAPILoaded,
        YT: window.YT,
        currentUrl: typeof window !== 'undefined' && window.location.href,
      });
    }
  };

  componentDidMount() {
    if (window && window.isYoutubeAPILoaded) {
      this.loadYoutubePlayer();
    } else {
      this.loadYoutubeAPI(this.loadYoutubePlayer);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  }

  restartVideo = () => {
    this.player.seekTo(0);
    this.player.playVideo();
  };

  startUpdateLoading = () => {
    this.updateLoading();
    this.interval = window.setInterval(
      this.updateLoading,
      this.player.getDuration() * 10,
    );
  };

  stopUpdateLoading = () => {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  };

  updateLoading = () => {
    if (document) {
      document.dispatchEvent(this.videoPlayingEvent);
    }
  };

  updateVideoRate = (event) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('videoSpeed', event.data);
    }
  };

  updateVideoQuality = (event) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('videoQuality', event.data);
    }
  };

  render() {
    const {videoId} = this.props;
    const {loading} = this.state;
    // The first div is necessary because when we destroy the player
    // it transform the iframe to a div, and it appears that
    // the React should control the top element.
    return (
      <div className="bg-black">
        <div className={cx('center', responsiveMaxWidth)}>
          <div className={cx('relative aspect-ratio aspect-ratio--16x9')}>
            <iframe
              title="youtube-video"
              allowFullScreen
              id="youtubePlayer"
              className={cx('aspect-ratio--object')}
              allow="autoplay"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&cc_load_policy=1&color=red&hl=ar&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=${origin}`}
              frameBorder="0"
            />
            {loading && (
              <div className="aspect-ratio--object flex justify-center items-center bg-light-gray">
                <LoadingIndicator />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default YoutubePlayer;
