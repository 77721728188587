import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {branch, compose} from 'recompose';
import NewTrackBox from './NewTrackBox';
import TracksFilters from './TracksFilters';
import withFilters from './withFilters';

const TracksWrapper = ({
  title,
  tracks,
  tracksProgress,
  stepsProgress,
  isDashboard,
  filters,
  handleChangeFilters,
  cy,
  published,
  authenticated,
  isDegreeBox,
  subscription,
  ...rest
}) => (
  <div className="w-100 tr">
    <div className="dn db-ns w-80-ns w-90 center pt5 pb0">
      <h4 className="f4 tr-ns tc black pb3 bb b--light-gray" data-cy={cy}>
        <FormattedMessage id={title} />
      </h4>
    </div>
    <div className="w-80-ns w-90 center mt4 mt0-ns">
      {filters && (
        <TracksFilters
          filters={filters}
          handleChangeFilters={handleChangeFilters}
        />
      )}
      <h4 className="dn-ns db mt5 f4 tr-ns tc black pb3 bb b--light-gray">
        <FormattedMessage id={title} />
      </h4>
      <div className="cf">
        {tracks.map((track) => (
          <NewTrackBox
            key={track.id}
            track={track}
            {...track}
            {...rest}
            trackProgress={!isDegreeBox ? tracksProgress[track.id] : null}
            stepsProgress={!isDegreeBox ? stepsProgress : null}
            isDashboard={isDashboard}
            authenticated={authenticated}
            isDegreeBox={isDegreeBox}
            subscription={subscription}
          />
        ))}
      </div>
    </div>
  </div>
);

TracksWrapper.propTypes = {
  isDashboard: PropTypes.bool,
  filters: PropTypes.array,
  stepsProgress: PropTypes.object,
  title: PropTypes.string.isRequired,
  tracks: PropTypes.array.isRequired,
  tracksProgress: PropTypes.object,
  filtersState: PropTypes.object,
  handleChangeFilters: PropTypes.func,
  cy: PropTypes.string,
  published: PropTypes.bool,
  authenticated: PropTypes.bool,
  isDegreeBox: PropTypes.bool,
};

const enhance = compose(branch(({hasFilters}) => hasFilters, withFilters));

export default enhance(TracksWrapper);
