import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import ArrowDown from '../Svg/ArrowDown';
import {bgCustomGray, bgCustomWhite, customSelect} from './styles.scss';

const TracksFilters = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {filters, handleChangeFilters} = props;
  return (
    <div className={cx('pv3 tr cf ph4 br2', bgCustomGray)}>
      {filters.map(({items, name}) => (
        <div key={name} className="w-third-ns w-100 fr pa3">
          <div
            className={cx('relative w-100 br2 ba b--mid-gray', bgCustomWhite)}
          >
            <select
              className={cx(
                'relative z-1 pv2 pr3 pl4 lh-copy w-100 bg-transparent bw0 b mid-gray f6',
                customSelect,
              )}
              name={name}
              defaultValue=""
              onChange={handleChangeFilters}
            >
              <option value="" disabled hidden>
                {formatMessage({id: `placeholder_${name}`})}
              </option>
              <option value="all">{formatMessage({id: `all_${name}`})}</option>
              {items.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <ArrowDown
              color="#666"
              className="absolute h-100 top-0 left-0 ml2"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

TracksFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
};

export default TracksFilters;
