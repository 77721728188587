import cx from 'classnames';
import NewDesignButton from 'modules/common/NewDesignButton';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import linkedInIcon from 'theme/assets/images/linkedin-icon.svg';

const LinkedInAddCertificateButton = ({className, trackTitle}) => {
  if (trackTitle)
    return (
      <NewDesignButton
        className={cx('bg-blue bg-animate hover-bg-dark-blue', className)}
        link={`https://www.linkedin.com/profile/add?startTask=${trackTitle}`}
        external
      >
        <FormattedMessage id="add_to_profile" />
        <img src={linkedInIcon} className="ph1" alt="LinkedIn" />
      </NewDesignButton>
    );
  return null;
};

LinkedInAddCertificateButton.propTypes = {
  className: PropTypes.string.isRequired,
  trackTitle: PropTypes.string,
};

export default LinkedInAddCertificateButton;
