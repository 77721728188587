import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({
  className = '',
  color = '#32AA6B',
  height = '10px',
  width = '10px',
}) => (
  <svg
    className={className}
    version="1.1"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 10.1 6.2">
    <path
      fill={color}
      d="M0,0.8c0-0.2,0.1-0.4,0.2-0.6c0.3-0.3,0.8-0.3,1.1,0l3.7,3.7l3.7-3.7c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L5.1,6.2L0.2,1.4C0.1,1.2,0,1,0,0.8z"
    />
  </svg>
);
ArrowDown.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ArrowDown;
