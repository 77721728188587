import React from 'react';
import PropTypes from 'prop-types';
import False from '../Svg/False';
import {fixedbar} from './styles.scss';

const HeaderModal = (props) => (
  <div className={`relative flex ${fixedbar} items-center bg-near-white w-100`}>
    <div className="tr flex2">
      <div className="w-20 grow mt3">
        <a onClick={props.buttonCloseModal} className="w-20 mr3 pointer">
          <False color="#212121" />
        </a>
      </div>
    </div>
    <h1 className="ma0 f3">{props.title}</h1>
    <div className="flex2 tl" />
  </div>
);

HeaderModal.propTypes = {
  buttonCloseModal: PropTypes.func,
  title: PropTypes.string,
};

export default HeaderModal;
