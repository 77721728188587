import cx from 'classnames';
import {fetchEntitiesIfNeeded} from 'core/api/actions.entities';
import CourseNextStepButton from 'modules/common/CourseNextStepButton';
import LinkedInAddCertificateButton from 'modules/common/LinkedInAddCertificateButton';
import NewDesignButton from 'modules/common/NewDesignButton';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';
import reload from 'theme/assets/images/reload.svg';

const {PARTNER} = process.env;

const NewTrackBoxFooterCompleted = ({
  certificateImgLink,
  id,
  slug,
  courses,
  name,
  isDegree,
  onClick,
}) => (
  <div className="flex justify-center items-center flex-row-ns flex-column lh-copy">
    <CourseNextStepButton
      className="nowrap-ns pa2 f6 bg-green-light bg-animate hover-bg-green"
      trackSlug={slug}
      courses={courses}
      isDegree={isDegree}
      onClick={onClick}
    >
      <div
        className={cx('flex justify-between justify-center', {
          'pv2 ph1': isDegree,
        })}
      >
        <div className="pl1">
          <FormattedMessage id={!isDegree ? 'review_track' : 'review_degree'} />
        </div>
        <img src={reload} alt="reload" />
      </div>
    </CourseNextStepButton>
    <div className="mh1-ns mv0-ns mv1" />
    {PARTNER === 'BARMEJ' && !isDegree && (
      <>
        {certificateImgLink ? (
          <LinkedInAddCertificateButton className="pa2 f6" trackTitle={name} />
        ) : (
          <NewDesignButton
            className="pv2 ph2 f6 bg-button-blue"
            link={`/account/certificates/${id}`}
          >
            <FormattedMessage id="certificates.ask_certificate" />
          </NewDesignButton>
        )}
      </>
    )}
  </div>
);

NewTrackBoxFooterCompleted.propTypes = {
  certificateImgLink: PropTypes.string,
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  courses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  name: PropTypes.string.isRequired,
  isDegree: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    ({entities}, {id}) => ({
      certificateImgLink: (
        (entities.certificates &&
          Object.values(entities.certificates).find(
            (certificate) =>
              typeof certificate === 'object' && certificate.track === id,
          )) ||
        {}
      ).png_link,
    }),
    (dispatch) => ({
      fetchCertificates: () => dispatch(fetchEntitiesIfNeeded('certificates')),
    }),
  ),

  lifecycle({
    componentDidMount() {
      const {fetchCertificates} = this.props;
      fetchCertificates();
    },
  }),
);
export default enhance(NewTrackBoxFooterCompleted);
