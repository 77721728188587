import {graphql} from '@apollo/client/react/hoc';
import dateDiffInDays from 'core/libs/helpers/dateDiffInDays';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import GET_MY_SUBSCRIPTIONS from 'modules/common/graphql/subscriptions.graphql';
import {compose, withProps} from 'recompose';

export default compose(
  graphql(GET_MY_SUBSCRIPTIONS, {
    skip: ({authenticated}) => !authenticated,
    props: ({data, ownProps}) => {
      const {error, loading, me} = data;
      if (loading) return {loading};
      if (error) return {error, loading: false};
      const subscriptions =
        me && me.subscriptionSet && me.subscriptionSet.edges;
      const {degrees} = ownProps;

      const filteredDegrees =
        degrees &&
        degrees.edges &&
        degrees.edges.filter(({node: {id}}) => {
          const subscriptionForCurrentDegree =
            subscriptions &&
            subscriptions.find(
              ({
                node: {
                  cohort: {degree},
                  status,
                },
              }) => id === (degree && degree.id) && status === 'PAID',
            );
          if (subscriptionForCurrentDegree) return false;
          return true;
        });

      return {
        degrees: {
          edges: filteredDegrees,
        },
      };
    },
  }),
  withProps(({degrees}) => {
    const filteredDegreesEdges =
      degrees &&
      degrees.edges &&
      degrees.edges.filter(({node: {cohortSet}}) => {
        if (cohortSet && cohortSet.edges && cohortSet.edges.length === 0)
          return false;
        const today = new Date();
        const hasFutureCohort =
          cohortSet &&
          cohortSet.edges &&
          cohortSet.edges.some(({node: {active, startDate}}) => {
            return dateDiffInDays(today, startDate) >= 1 || active;
          });
        return hasFutureCohort;
      });
    return {
      degrees: {edges: filteredDegreesEdges},
    };
  }),
  spinnerWhileLoading(({authenticated, loading}) => authenticated && loading),
);
