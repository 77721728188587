import notification from 'core/libs/helpers/notification';
import {navigate, replace} from 'gatsby';
import {signOut} from 'modules/auth/actions/sign-out';
import {ENTITIES_URL} from './reducer.js';

export const fetchEntitiesIfNeeded = (
  category,
  force,
  showLoadingBar = true,
) => (dispatch) => {
  dispatch({
    types: [
      'GET_ENTITIES_REQUEST',
      'GET_ENTITIES_SUCCESS',
      'GET_ENTITIES_FAILURE',
    ],
    config: {
      url: ENTITIES_URL[category],
    },
    loadingBar: showLoadingBar,
    shouldCallAPI: ({entities}) => {
      if (force) {
        return true;
      }
      if (!entities[category]) {
        return true;
      }
      if (
        entities[category].status !== 'fetched' &&
        entities[category].status !== 'fetching'
      ) {
        return true;
      }
      return false;
    },
    payload: {category},
    callback: (passed, data) => {
      if (!passed) {
        if (data.response) {
          if (data.response.status === 403) {
            if (
              data.response.data &&
              data.response.data.message ===
                'Invalid authentication credentials'
            ) {
              notification({id: 'session_expired'});
              dispatch(signOut());
              navigate('/auth/login');
            } else replace('/403');
          }
          if (data.response.status === 404) {
            notification({id: '404'});
            navigate('/');
          }
        }
      }
    },
  });
};

export default fetchEntitiesIfNeeded;
