import NewDesignButton from 'modules/common/NewDesignButton';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose, setPropTypes, withProps} from 'recompose';

const CourseNextStepButton = ({
  children,
  className = '',
  onClick,
  nextStep: {url, isLocked},
  subscription,
  btnUrl,
  stage,
}) => (
  <NewDesignButton
    className={className}
    link={subscription === 'ACTIVE' || !isLocked ? url : stage ? btnUrl : '/subscription'}
    state = {{ fromLink: url }}
    onClick={onClick}
  >
    {children}
  </NewDesignButton>
);

CourseNextStepButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  nextStep: PropTypes.object.isRequired,
};

export const withNextStepData = compose(
  connect(({user}) => {
    return {
      coursesProgress: user.progress.course || {},
      stagesProgress: user.progress.stage || {},
      stepsProgress: user.progress.step || {},
    };
  }),

  setPropTypes({
    courses: PropTypes.arrayOf(PropTypes.object),
    coursesProgress: PropTypes.object.isRequired,
    isDegree: PropTypes.bool,
    stagesProgress: PropTypes.object.isRequired,
    stepsProgress: PropTypes.object.isRequired,
    trackSlug: PropTypes.string.isRequired,
  }),

  withProps(
    ({
      courses,
      coursesProgress,
      isDegree,
      stagesProgress,
      stepsProgress,
      trackSlug,
    }) => {
      let url = isDegree ? '/degrees' : '';
      const additionalData = {};
      let currentCourse = {};
      let currentStage = {};
      let currentStep = {};
      const nextCourse =
        courses &&
        (courses.find(({id, name, soon, stages}) => {
          currentCourse = {id, name, soon, stages};
          return !soon && coursesProgress[id] !== 'completed';
        }) ||
          courses[0]);

      if (currentCourse.id) {
        currentStage =
          currentCourse &&
          currentCourse.stages &&
          (coursesProgress[currentCourse.id] === 'completed'
            ? currentCourse.stages[0]
            : currentCourse.stages.find(
                (stage) => stagesProgress[stage.id] !== 'completed',
              ));

        currentStep =
          currentStage &&
          currentStage.steps &&
          (currentStage.steps.find(
            (step) => stepsProgress[step.id] !== 'completed',
          ) ||
            (currentStage && currentStage.steps[0]));
      }
      // empty track
      if (!nextCourse) {
        return {nextStep: {url: `${url}/${trackSlug}`}};
      }
      additionalData.courseName = nextCourse.name;
      additionalData.courseSlug = nextCourse.slug;

      url += `/${trackSlug}/${nextCourse.slug}`;

      if (!nextCourse.stages || nextCourse.stages.length === 0) {
        return {nextStep: {url}};
      }
      const nextStage =
        nextCourse.stages.find(({id}) => {
          return stagesProgress[id] && stagesProgress[id] !== 'completed';
        }) || nextCourse.stages[0];

      const steps = nextStage && nextStage.steps;
      if (steps && steps.length > 0) {
        const nextStep =
          (steps &&
            steps.find(({id}) => {
              return stepsProgress[id] !== 'completed';
            })) ||
          (steps && steps[0]);
        if (nextStep) {
          url += `/${nextStage.slug}/${nextStep.slug}`;
          additionalData.stageName = nextStage.name;
          additionalData.stepName = nextStep.name;
          additionalData.isLocked = nextStep.isLocked;
        }
      }

      return {
        coursesProgress,
        currentCourse,
        currentStage,
        currentStep,
        nextStep: {
          ...additionalData,
          url,
        },
      };
    },
  ),
);

export default withNextStepData(CourseNextStepButton);
