import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({percentage, className}) => {
  const percentageAsText = `${Math.round(percentage)}%`;
  return (
    <div className={`w-100-m w-100 ${className}`}>
      <p className="tr f6 fw6 mb2">{percentageAsText}</p>
      <div className="w-100 center bg-light-gray br-pill cf">
        {/* show only if greater than ZERO */}
        <div
          className={`fr pa1 br-pill ${percentage ? 'bg-green-light' : ''}`}
          style={{width: percentageAsText}}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default ProgressBar;
