import objectives from 'data/objectives.json';
import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  withProps,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';

export default compose(
  setPropTypes({
    hasFilters: PropTypes.bool.isRequired,
  }),
  withStateHandlers(
    {
      filtersState: {
        level: '',
        objective: '',
        topic: '',
      },
    },
    {
      handleChangeFilters: ({filtersState}) => (e) => ({
        filtersState: {
          ...filtersState,
          [e.target.name]: e.target.value === 'all' ? '' : e.target.value,
        },
      }),
    },
  ),
  setPropTypes({
    filtersState: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
  }),
  withPropsOnChange(['filtersState', 'tracks'], ({filtersState, tracks}) => {
    const objective =
      filtersState.objective === ''
        ? undefined
        : objectives.find(({text}) => filtersState.objective === text);

    const filteredTracks = tracks
      .filter(({slug}) => !objective || objective.trackSlugs.includes(slug))
      .filter(
        ({level}) => filtersState.level === '' || filtersState.level === level,
      )
      .filter(
        ({topic}) =>
          filtersState.topic === '' ||
          !topic ||
          filtersState.topic === topic.name,
      );
    return {
      tracks,
      filteredTracks,
    };
  }),
  withPropsOnChange(
    ['filteredTracks', 'filtersState'],
    ({filteredTracks, filtersState, tracks}) => {
      const getTracksToUse = (prop) =>
        filtersState[prop] ? tracks : filteredTracks;
      const filters = [
        {
          name: 'objective',
          items: objectives
            .filter(({trackSlugs}) =>
              trackSlugs.some((trackSlug) =>
                getTracksToUse('objective').some(
                  ({slug}) => slug === trackSlug,
                ),
              ),
            )
            .map(({text}) => text),
        },
        {
          name: 'level',
          items: Object.keys(
            getTracksToUse('level').reduce((acc, track) => {
              acc[track.level] = true; // eslint-disable-line no-param-reassign
              return acc;
            }, {}),
          ),
        },
        {
          name: 'topic',
          items: Object.keys(
            getTracksToUse('topic').reduce((acc, track) => {
              if (track.topic) {
                acc[track.topic.name] = true; // eslint-disable-line no-param-reassign
              }
              return acc;
            }, {}),
          ).sort((item1, item2) => (item1 > item2 ? 1 : -1)),
        },
      ];
      return {filters};
    },
  ),
  withProps(({filteredTracks}) => ({tracks: filteredTracks})),
);
