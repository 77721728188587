import isRequired from './isRequired';

function humanDuration(duration = isRequired('duration', 'humanDuration')) {
  // We remove seconds
  let rest = Math.floor(duration / 60);
  let text = '';
  // Hours
  if (rest >= 60) {
    const hours = Math.floor(rest / 60);
    rest -= hours * 60;
    switch (hours) {
      case 1:
        text += 'ساعة';
        break;
      case 2:
        text += 'ساعتان';
        break;
      default:
        if (hours <= 10) {
          text += `${hours} ساعات`;
        } else {
          text += `${hours} ساعة`;
        }
    }
  }
  // Minutes
  if (rest > 0 && rest < 60) {
    const minutes = rest;
    if (text !== '') {
      text += '، ';
    }
    switch (minutes) {
      case 1:
        text += 'دقيقة';
        break;
      case 2:
        text += 'دقيقتان';
        break;
      default:
        if (minutes <= 10) {
          text += `${minutes} دقائق`;
        } else {
          text += `${minutes} دقيقة`;
        }
    }
  }
  return text;
}

export default humanDuration;
